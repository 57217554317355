.vuln-container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    flex-wrap: wrap;
    justify-content: center; 
}

.vuln {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 75%;
    width: 20em;
    box-sizing: border-box;
    margin-bottom: 1em;
    line-height: 1.6;
}

.vuln-type {
    margin-top: 1em;
    text-align: center;
}

.vuln-name {
    margin-top: 1em;
    text-align: center;
    padding-left: 2em;
    padding-right: 2em;
}

.topdec {
    background: #ffbc42;
}
