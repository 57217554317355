.horiz-container-health {
	display: flex;
	flex-direction: row;
    overflow-x: auto;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center; 
}

.health-img {
	display: flex;
	flex-direction: row;    
    align-items: center;
    justify-content: center;
}

.direction-img {
	display: flex;
	flex-direction: row;    
    align-items: center;
    justify-content: center;
    padding-bottom: 0.1em;
}

.vert-container-health {
	display: flex;
	flex-direction: column;
    text-align: center;
    font-size: 80%;
    width: 20em;
    box-sizing: border-box;
    margin-bottom: 1em;
    line-height: 1.6;    
}
.health-text {
    padding-left: 2em;
    padding-right: 2em;
}


