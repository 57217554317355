
.adaptation .content {
    background-color: #e6eced;
    padding: 1em;
}

.adaptation .metadata {
    color: #d6dcddff;	
}



.adaptation.collapsible {
    padding-bottom: 0.5em;
    width: auto;
    display: block;
}

.adaptation.header {
    background: #115158;
    color: #f7f7f7;
}

.adaptation.header:hover {
	background: #f7f7f7;
    color: #115158;
}

.adaptation.header:hover .arrow {
    border: solid  #115158;
    border-width: 0 3px 3px 0;
}

.arrow {
    position: absolute;
    right: 1em;
    top: 1em;    
    border: solid #f7f7f7;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transition: transform 300ms linear;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
