.network {
	display: flex;
	flex-direction: column;
}

.fullscreen-enabled .network {
	display: flex;
	flex-direction: row;
    background: white;
    height: 100vh;
}

@media (min-width: 1200px) {
	.network {
		flex-direction: row;
	}
}

.network-holder {
    height: 400px;
    border: 1px solid black;
    box-sizing: border-box;
}

@media (min-width: 1200px) {
	.network-holder {
        height: 600px;
    }
}

@media (min-width: 1200px) {
	.network-holder {
		display: flex;
		flex: 1;
	}
}

@media (min-width: 2000px) {
	.network-holder {
        height: 750px;
    }
}

.fullscreen-enabled .network-holder {
    height: 100vh;
}

.fullscreen-enabled .network-info {
	width: 20%;
	height: 100%;
}



/* turn off fullscreen on mobile, as it's full width anyway */
.fullscreen-button {
    display: none;
}

@media (min-width: 1200px) {
	.fullscreen-button {
		display: block;
	}
}



.network-info {
	width: auto;
	height: auto;
	background: #e6ecedff;	
	padding: 1em;
	overflow-y: auto;
    box-sizing: border-box;
}

.network-info h2 {
    margin-left: 0;
    margin-right: 0;
    font-size: 120%;
}

@media (min-width: 1200px) {
	.network-info {
		width: 16em;
	    height: 600px;
		overflow-y: auto;
		font-size: 0.75em;
	}
}

@media (min-width: 2000px) {
	.network-info {
        height: 750px;
    }
}


.network-info h3 {
	margin-block-start: 0;
}

/* network styles -------------------------------------- */

g span {
	background: none !important;
	background-color: none !important;
	font-size: 3em;
}

.node {
	max-width: 20px;
}

.node rect,
.node circle,
.node ellipse {
	stroke: #333;
	fill: #fff;
	stroke-width: 1.5px;
}

.cluster rect {
	stroke: #333;
	fill: #000;
	fill-opacity: 0.1;
	stroke-width: 1.5px;
}

.edgePath path.path {
	stroke: #333;
	stroke-width: 1.5px;
	fill: none;
}

.net-node-simple {
	width: 180px;
	height: 180px;
	text-align: center;
	font-size: 0.75em;
	white-space: normal;
	padding-top: 8px;
}

.net-node-simple-vertical-center {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 100%;
}

.net-node-complex {
	width: 300px;
	height: 300px;
	text-align: center;
	white-space: normal;
	text-align: left;
}

.net-node-complex ol {
	font-size: 0.75em;
	padding-inline-start: 20px;
}

.net-node-image-holder {
	width: 100%;
	text-align: center;	
}

.metadata {
    color: #d6dcddff;	
}

div.vis-network div.vis-navigation div.vis-button.vis-up {
/*    background-image: url("../images/decrease.svg"); */
}
div.vis-network div.vis-navigation div.vis-button.vis-down {
/*    background-image: url("../images/decrease.svg"); */
}
div.vis-network div.vis-navigation div.vis-button.vis-left {
/*    background-image: url("../images/decrease.svg"); */
}
div.vis-network div.vis-navigation div.vis-button.vis-right {
/*    background-image: url("../images/decrease.svg"); */
}

div.vis-network div.vis-navigation div.vis-button.vis-zoomExtends {
    display: none;
}
