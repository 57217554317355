@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat-Medium'),
        url('../fonts/Montserrat-Medium.ttf');
}

@font-face {
  font-family: 'Montserrat-Black';
  src: local('Montserrat-Black'),
        url('../fonts/Montserrat-Black.ttf');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: local('Montserrat-SemiBold'),
        url('../fonts/Montserrat-SemiBold.ttf');
}

body {
    box-sizing: border-box;
    width: 100%;
    background: #f7f7f7;
	color: black;
    font-family: 'Montserrat-Medium',Arial,Helvetica,sans-serif;
    font-size: 1em;
    padding: 0;
    margin: 0;
    line-height: 1.9; /* measured to match iOS when selects push the text apart */
}


h1 {
    font-family: 'Montserrat-Black',Arial,Helvetica,sans-serif;
	font-size: 150%;
	margin-bottom: 0em;
    color: #115158ff;
    text-transform: uppercase;              
}

h2 {
    font-family: 'Montserrat-SemiBold',Arial,Helvetica,sans-serif;
    border-bottom: 3px solid #f5821fff;
    text-transform: uppercase;              
}

@media (min-width: 1000px) {
    body {
		font-size: 1.2em;
    }
}    


@media (min-width: 1500px) {
    body {
		font-size: 2em;
    }
}

select {
    font-family: 'Montserrat-Medium',Arial,Helvetica,sans-serif;
    border: none;
	background: #b2e1bcff;
	font-size: 1em;
	border-radius: 5px;
    padding: 0em;
}

select option {
	background: #b2e1bcff;
}

select:hover {
	background: #f5821fff;
}

a { color: #115158ff; }

button {
    font-family: 'Montserrat-SemiBold',Arial,Helvetica,sans-serif;
	border: none;
	background: #ffd768ff;
	font-size: 1em;
	border-radius: 2px;
}

button:hover {
	background: #f5821fff;
}

/* Style the button that is used to open and close the collapsible content */
.collapsible {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: content-box;
	position: relative;
	border: none;
	border-radius: 5px;	
}

.collapsible .header {
    font-family: 'Montserrat-SemiBold',Arial,Helvetica,sans-serif;
    text-align: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 2em;
    padding-right: 2em;
	background: #ffd768ff;
	border-radius: 5px;
}

.collapsible .header:hover {
	background: #f5821fff;
}

/* Style the collapsible content. Note: hidden by default */
.collapsible-content {
	padding-left: 2em;
	padding-right: 2em;
	overflow: hidden;  /* Hide the element content, while height = 0 */
	max-height: 0;
	transition: max-height 0.5s ease-out;
}

.collapsible-content.visible {
	max-height: 500px;
	transition: max-height 0.5s ease-in;
}

.horiz-container {
	display: flex;
	flex-direction: row;
    align-items: center;
    overflow-x: auto;
    justify-content: space-around;
}

.vert-container {
	display: flex;
	flex-direction: column;
    align-items: center;
    justify-content: center;
}

.note {
    color: #115158;
    font-size: 80%;
}

.footer {
    margin-top: 5em;
    padding: 1em;
    background: #f7f7f7;
    font-size: 60%;
    text-align: center;
}

.email-button {
    display: inline-block;
    width: 50px;
    height: 50px;
    background: url("images/email_button.png") no-repeat;
    background-size: 100% 100%; 
}

.email-button:hover {
    background: url("images/email_button_selected_yellow.png") no-repeat;
    background-size: 100% 100%; 
}

.projected-regions {
    font-family: 'Montserrat-SemiBold',Arial,Helvetica,sans-serif;    
}

.logo-block {
    width: 100%;
    margin-top: 2em;
}

.logos {
    content: url("images/logos/v2_new.png");
    width: 100%;
    max-width: 1000px;
}

.funder-logos {
    content: url("images/logos/funder logos.png");
    width: 100%;
    max-width: 1000px;
}

.white-section {
    background: #f7f7f7;
    padding-top: 0.5em;
    padding-bottom: 1.5em;
    padding-left: 3em;
    padding-right: 3em;
}

.grey-section {
    background: #e8efef;
    padding-top: 0.5em;
    padding-bottom: 1.5em;
    padding-left: 3em;
    padding-right: 3em;
}

/*@media (min-width: 1000px) {
    .white-section {
        padding-left: 50px;
        padding-right: 50px;
    }
    .grey-section {

        padding-left: 50px;
        padding-right: 50px;
    }
}*/

.title {
    display: flex;
}

.dropdown {
    margin-top: 1.5em;
    margin-left: 1em;
}

.caveat {    
    padding: 2px;
    border-radius: 5px;
    background: #b2e1bc;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    color: black;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 0.5em;
    z-index: 1;
    font-size: 75%;
    line-height: 1.2em;
}

.dropdown:hover .dropdown-content {
    display: block;
}


