.leaflet-container {
    width: 100%;
    height: 500px;
    z-index: 0;
    border: 2px solid #333;
    overflow: hidden;
    box-sizing: border-box;
    margin: 0;
}

@media (min-width: 2000px) {
    .leaflet-container {
        height: 750px;
    }
}

.map-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}

@media (min-width: 1000px) {
	.map-container {
		flex-direction: row;
	}
}

.climate-map {
	flex: 1 0 350px;
    width: 100%;
}

@media (min-width: 1000px) {
    .climate-map {
        width: auto;
    }
}

.map-selection {
	padding-left: 1em;
    padding-right: 1em;
    box-sizing: border-box;
 	width: 100%;
    overflow-y: auto;
    background: #e6eced;
    height: auto;
    padding-bottom: 1em;
}

@media (min-width: 1000px) {
	.map-selection {
	    margin-top: 1em;
		margin-top: 0;
		width: 10em;
        height: 500px;
        padding-bottom: 0;
	}
}

@media (min-width: 2000px) {
	.map-selection {
        height: 750px;
    }
}

.map-selection h2 {
	font-size: 0.75em;
}

.map-selection ul {
    font-size: 75%;
	padding: 0;
	margin: 0;
    margin-bottom: 0.5em;
}

.map-selection li {
	list-style-type: none;
	border-bottom: 2px solid #ffbc42;
	margin-right: 1em;
}

.map-selection button {
    font-size: 75%;
}
